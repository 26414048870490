import React from 'react';
import { Link } from 'react-router-dom';
import './HomePage.css'; // Import the CSS file for HomePage

function HomePage() {
  return (
    <div className="HomePage">
      <div className="home-content">
        <h1>Welcome to Oaktoon Art</h1>
        <p>Select a comic chapter to start reading.</p>

        {/* Navigation Links */}
        <nav className="home-nav">
          <Link to="/comic1" className="home-link">Chapter One</Link>
         <Link to="/comic2" className="home-link">Chapter Two</Link>
         <Link to="/comic3" className="home-link">Chapter Three</Link>
        </nav>
      </div>
    </div>
  );
}

export default HomePage;

const comic3Images = [
  {
    src: '/images/comic3/page1.jpg', alt: 'Comic Page 3',
    original: '/images/comic3/page1.jpg',
    thumbnail: '/images/comic3/thumb1.jpg',
    description: 'Comic 3 - Page 1',
    originalAlt: 'Comic 3 - Page 1',
  },
  {
    src: '/images/comic3/page2.jpg', alt: 'Comic Page 3',
    original: '/images/comic3/page2.jpg',
    thumbnail: '/images/comic3/thumb2.jpg',
    description: 'Comic 3 - Page 2',
    originalAlt: 'Comic 3 - Page 2',
  },
  // Add more images as needed
];

export default comic3Images;

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ComicViewer from './components/ComicViewer';
import HomePage from './components/HomePage';

// Import image data
import comic1Images from './data/comic1Images';
import comic2Images from './data/comic2Images';
import comic3Images from './data/comic3Images';
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        {/* Define Routes */}
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route
            path="/comic1"
            element={<ComicViewer images={comic1Images} title="Beyond: Chapter One - The Relic" />}
          />
          <Route
            path="/comic2"
            element={<ComicViewer images={comic2Images} title="Beyond: Chapter Two - Our Heroes" />}
          />
          <Route
           path="/comic3"
           element={<ComicViewer images={comic3Images} title="Beyond: Chapter Three - " />}
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;

import React, { useState } from 'react';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import { Link } from 'react-router-dom';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
import 'yet-another-react-lightbox/plugins/thumbnails.css';

import './ComicViewer.css';

// Example import of comic images (adapt based on your file structure)
import comic1Page1 from '../images/comic1/page1.jpg';
import comic1Page2 from '../images/comic1/page2.jpg';
import comic1Page3 from '../images/comic1/page3.jpg';
import comic1Page4 from '../images/comic1/page4.jpg';
import comic2Page1 from '../images/comic2/page1.jpg';
import comic2Page2 from '../images/comic2/page2.jpg';

function ComicViewer({ images, title }) {
  const [open, setOpen] = useState(false);

  console.log(images);
  
    // Disable right-click on images
  const disableRightClick = (e) => {
    e.preventDefault();
    alert('Right-click is disabled on this image.');
  };

/*
  // Define your slides (images)
  const slides = [
    { src: comic1Page1, alt: 'Comic Page 1' },
    { src: comic1Page2, alt: 'Comic Page 2' },
    { src: comic1Page3, alt: 'Comic Page 3' },
    { src: comic1Page4, alt: 'Comic Page 4' },
    { src: comic2Page1, alt: 'Comic Page 1' },
    { src: comic2Page2, alt: 'Comic Page 2' },
    // Add more images as needed
  ];
*/
  const thumbnailsRef = React.useRef(null);

  return (
    <div className="ComicViewer">
     <div className="rectangle-container">
      <div className="nav-buttons">
       <Link to="/" className="back-button">
        &larr; Back to Home
      </Link>
    </div>
      <h1>{title}</h1>
      {/* Button to open the lightbox */}
      <button onClick={() => setOpen(true)}>Open Comic Selection</button>
    </div>

      {/* Lightbox Component */}
      <Lightbox
        open={open}
        close={() => setOpen(false)}
        slides={images}
        plugins={[Zoom, Thumbnails]}
        thumbnails={{ rev: thumbnailsRef }}
        on={{
          click: () => {
           (thumbnailsRef.current?.visible
             ? thumbnailsRef.current?.hide
             : thumbnailsRef.current?.show)?.();
          },
        }}
      />
    </div>
  );
}

export default ComicViewer;
